import React, { useEffect } from 'react';
import { Box } from 'rebass';
import styled from 'styled-components';
import { useHSFormsScriptLoaded } from '../../helpers/hooks';

const EbookPageForm = styled.div`
  order: 2;
  border-radius: 16px;
  border: 1px solid var(--neutral-gray-200-strokes, #d0d0d0);
  background: var(--shade-white-0, #fff);
  padding: 20px 20px 20px 20px;
  min-height: fit-content;
  @media (max-width: 576px) {
    margin-left: 0;
  }

  @media (max-width: 768px) {
    order: 1;
    margin-bottom: 20px;
  }
`;

const Title = styled.h3`
  color: var(--primary-blue-500-main, #00a5c8);
  font-family: 'Rubik';
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: -0.5px;
`;

const GatedContentForm = ({ eBookTitle, eBookURL }) => {
  const HSFormsScriptLoaded = useHSFormsScriptLoaded();

  useEffect(() => {
    if (HSFormsScriptLoaded) {
      window.hbspt.forms.create({
        region: 'na1',
        portalId: '14573977',
        formId: 'de37d81a-75fa-46b5-9c8b-805df64f52fa',
        target: '#ebook-page-form',
        onFormSubmit: () => {
          window.open(`${eBookURL}`, '_blank');
        },
      });
    }
  }, [HSFormsScriptLoaded]);

  return (
    <Box>
      <Title>{eBookTitle}</Title>
      <EbookPageForm id='ebook-page-form' className='nexton-form' />
    </Box>
  );
};

export default GatedContentForm;
