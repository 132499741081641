import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { PortableText } from '@portabletext/react';
import { Box } from 'rebass';
import MetaData from '../../../components/MetaData';
import Layout from '../../../components/layout/Layout';
import EBookGrid from '../../../components/resources/EBookGrid';
import GatedContentForm from '../../../components/resources/EBookForm';

const MainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
`;
const TitleContainer = styled.div`
  margin-bottom: 120px;

  @media (max-width: 1199px) {
    width: 90%;
    margin-bottom: 90px;
  }
`;
const Title = styled.h1`
  color: var(--primary-blue-500-main, #00a5c8);
  text-align: center;
  font-family: 'Rubik';
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
  line-height: 68px;
  letter-spacing: -1px;

  @media (max-width: 1199px) {
    font-size: 42px;
    line-height: 60px;
  }
`;
const SubTitle = styled.h4`
  color: var(--shade-black-900, #202323);
  text-align: center;
  font-family: 'Rubik';
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: -0.5px;
  margin-bottom: 0px;
`;
const EBookFormContainer = styled.div`
  width: 80%;
  display: flex;
  flex-direction: row;
  margin-bottom: 120px;
  justify-content: space-around;

  @media (max-width: 1199px) {
    width: 90%;
    margin-bottom: 40px;
    flex-direction: column;
    align-items: center;
  }
`;
const ImageContainer = styled.div`
  width: 45%;
  display: flex;
  flex-direction: row;
  justify-content: center;

  @media (max-width: 1199px) {
    width: 100%;
    margin-bottom: 40px;
  }
`;
const EBookImage = styled.img`
  width: 60%;
  display: flex;
  align-self: center;
  margin-top: 20px;
`;
const FormContainer = styled.div`
  width: 45%;

  @media (max-width: 1199px) {
    width: 100%;
  }
`;
const DescriptionContainer = styled.div`
  width: 75%;
  display: flex;
  flex-direction: row;
  margin-bottom: 120px;

  @media (max-width: 1199px) {
    width: 90%;
    margin-bottom: 90px;
  }
`;
const TextContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  h1,
  h2,
  h3,
  h4 {
    color: var(--primary-blue-500-main, #00a5c8);
    font-size: 28px;
    line-height: 38px;
  }

  p {
    color: var(--shade-black-900, #202323);
    font-size: 22px;
    line-height: 40px;

    @media (max-width: 1199px) {
      font-size: 20px;
      line-height: 30px;
    }
  }

  p,
  h1,
  h2,
  h3,
  h4 {
    strong {
      font-weight: 700; // adjust this value as per your requirement
    }
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    letter-spacing: -0.5px;

    @media (max-width: 1199px) {
      width: 100%;
      letter-spacing: 0px;
    }
  }

  @media (max-width: 1199px) {
    width: 100%;
  }
`;
const TabletImageContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;

  @media (max-width: 1199px) {
    width: 0%;
    display: none;
  }
`;
const OtherEbooksContainer = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 120px;

  @media (max-width: 1199px) {
    width: 90%;
    margin-bottom: 90px;
  }
`;
const OtherEbooksTitle = styled.h2`
  color: var(--primary-blue-500-main, #00a5c8);
  font-family: 'Rubik';
  font-size: 42px;
  font-style: normal;
  font-weight: 400;
  line-height: 60px;
  letter-spacing: -1px;
  margin: 0px;

  @media (max-width: 1199px) {
    font-size: 28px;
    line-height: 38px;
    letter-spacing: -0.5px;
  }
`;
const OtherEbooksSubTitle = styled.h4`
  color: var(--shade-black-900, #202323);
  font-family: Rubik;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: -0.5px;
  margin-bottom: 50px;
`;
const EbooksContainer = styled.div`
  width: 100%;
`;

const EBookTemplate = ({ data }) => {
  const eBook = data?.sanityEbook;
  return (
    <>
      <MetaData title={eBook?.hubTitle} description={eBook?.metaDescription} image={eBook?.shareImage?.asset?.url} />
      <Layout selected='resources'>
        <Box className='full-width'>
          <MainContainer>
            <TitleContainer>
              <Title>
                <strong>Get full access</strong> to featured E-book
              </Title>
              <SubTitle>
                Gain access to Nexton's exclusive content and discover invaluable insights that will revolutionize your
                hiring strategies.
              </SubTitle>
            </TitleContainer>
            <EBookFormContainer>
              <ImageContainer>
                <EBookImage src={eBook?.ebookImage?.asset.url.images.fallback.src} alt={eBook?.ebookImageAlt} />
              </ImageContainer>
              <FormContainer>
                <GatedContentForm eBookTitle={eBook?.hubTitle} eBookURL={eBook?.eBookURL} />
              </FormContainer>
            </EBookFormContainer>
            <DescriptionContainer>
              <TextContainer>
                <PortableText value={eBook?.description} />
              </TextContainer>
              <TabletImageContainer>
                <EBookImage src={eBook?.tabletImage?.asset.url} alt={eBook?.tabletImageAlt} />
              </TabletImageContainer>
            </DescriptionContainer>
            <OtherEbooksContainer>
              <OtherEbooksTitle>Other E-books</OtherEbooksTitle>
              <OtherEbooksSubTitle>
                Explore our collection of ebooks to enhance your hiring techniques and gain a competitive edge in your
                industry.
              </OtherEbooksSubTitle>
              <EbooksContainer>
                <EBookGrid pageView currentEbookSlug={eBook?.slug.current} />
              </EbooksContainer>
            </OtherEbooksContainer>
          </MainContainer>
        </Box>
      </Layout>
    </>
  );
};

export default EBookTemplate;

export const query = graphql`
  query SanityEbook($slug__current: String!) {
    sanityEbook(slug: { current: { eq: $slug__current } }) {
      hubTitle
      metaDescription
      shareImage {
        asset {
          url
        }
      }
      slug {
        current
      }
      eBookURL
      ebookImage {
        asset {
          url: gatsbyImageData(
            width: 431
            height: 554
            placeholder: NONE
            layout: CONSTRAINED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      ebookImageAlt
      tabletImage {
        asset {
          url
        }
      }
      tabletImageAlt
      description {
        _key
        _rawChildren
        _type
        children {
          _key
          marks
          _type
          text
        }
        list
        style
      }
    }
  }
`;
